

export function phoneNumber(numStr: string) {
  numStr = numStr.replace(/[^\d]/g, '');
  if (numStr.length === 11) {
    return `${numStr.slice(0,1)} (${numStr.slice(1,4)}) ${numStr.slice(4,7)}-${numStr.slice(7, numStr.length)}`;
  } else if (numStr.length === 10) {
    return `(${numStr.slice(0,3)}) ${numStr.slice(3,6)}-${numStr.slice(6, numStr.length)}`;
  } else if (numStr.length === 9) {
    return `${numStr.slice(0,3)}-${numStr.slice(3,6)}-${numStr.slice(6,numStr.length)}`;
  } else {
    return `${numStr.slice(0,4)}-${numStr.slice(4, numStr.length)}`;
  }
}


export function currency(num: number | string): string {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return num < 0 ? '-$' + (-num).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : '$' + num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

interface currencyOptions {
  precision: number;
  unit: string;
  separator: string;
  delimiter: string;
}

export function internationalCurrency(num: number, options: currencyOptions): string {
  try {
    var options = options;
    var precision = options["precision"] || 2;
    var unit = options["unit"] || "\u20AC";
    var separator = precision > 0 ? options["separator"] || "." : "";
    var delimiter = options["delimiter"] || ",";
    var parts = parseFloat(num.toString()).toFixed(precision).split('.');

    return unit + ' ' + number_with_delimiter(parts[0], delimiter, separator) + separator + parts[1].toString();
  } catch(e) {
    return num.toString();
  }
}

function number_with_delimiter(number: string, delimiter: string, separator: string): string {
  try {
    var delimiter = delimiter || ",";
    var separator = separator || ".";
    var parts = number.split('.');

    if (parts[0].length >= 4) {
        parts[0] = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (parts[1] && parts[1].length >= 4) {
        parts[1] = parts[1].replace(/(\d{3})/g, '$1 ');
    }

    return parts.join(separator);

  } catch(e) {
    return number.toString();
  }
}

export function parse(num: number| string, precision: number): string {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  if (precision >= 0) {
    return num.toLocaleString(undefined, { minimumFractionDigits: precision, maximumFractionDigits: precision });
  } else {
    return num.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  }
}

interface BigNumberOptions {
  minAmount?: number;
}

export function bigNumber (amount: number, options: BigNumberOptions): string {
  options = options || {};
  amount = Math.round(amount);
  if (options.minAmount && amount < options.minAmount) {
    return `${amount}`;
  }
  return `${amount >= 1000 ? Math.floor(amount / 1000) + 'K' : amount}`;
}
