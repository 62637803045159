import { validateForm } from "../../utilities/form";


const Unsuspend = {

  formSelector: '#unsuspend-form',
  wizardObject: null,

  setLineIds() {
    $('#line-ids').val(window.vMox.Selections.selectedIds().join(","));
  },

  bindAssignmentSelect() {
    $('#assign-to').select2({
      placeholder: "Select employee",
      minimumInputLength: 3,
      ajax: {
        url: "/contacts/ajax_select",
        data: function (params) {
          var ajaxData: any = {};
          ajaxData.search = params.term;
          return ajaxData;
        }.bind(this),
        dataType: 'json',
        processResults: function (data) {
          return {
            results: data.data
          };
        }
      },
      templateResult: function (item) {
        if (item.text) {
          return item.text;
        }
        return `
          ${item.firstName} ${item.lastName} (${item.email})
        `;
      },
      templateSelection: function (item) {
        if (item.text) {
          return item.text;
        }
        return `
          ${item.firstName} ${item.lastName} (${item.email})
        `;
      },
      width: '100%'
    });
  },

  initialize() {
    Unsuspend.initializeWizardValidation();
    Unsuspend.bindAssignmentSelect();
  },

  initializeWizardValidation() {
    let $form2 = $(Unsuspend.formSelector);

    $form2.on('keydown', function (e) {
      if (e.key === 'Enter' && $(e.target).prop('tagName') !== 'TEXTAREA') {
        e.preventDefault();
      }
    });
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);


    var dateRules: any = {
      required: true,
      after: yesterday
    };
    if (window.vMox.userPreferredDateFormat === window.vMox.INTERNATIONAL_DATE_FORMAT) {
      dateRules.dateITA = true;
    } else {
      dateRules.date = true;
    }
    console.log(dateRules);

    validateForm($form2, {
      'request[unSuspendDate]': dateRules,
    }, {
      messages: {
        'request[unSuspendDate]': {
          after: 'You must select a date in the future'
        }
      }
    });

    Unsuspend.wizardObject = $('.js-wizard-validation').bootstrapWizard({
      tabClass: '',
      previousSelector: '.wizard-prev',
      nextSelector: '.wizard-next',
      onTabShow($tab, $nav, $index) {
        const total = $nav.find('li').length;
        const current = $index + 1;

        const $wizard = $nav.parents('.block');
        const $btnNext = $wizard.find('.wizard-next');
        const $btnFinish = $wizard.find('.wizard-finish');

        if ($tab[0].innerText === 'Review') {
          $btnNext.prop('disabled', true); // Disable the Next button on Review until the contents load
        } else {
          $btnNext.prop('disabled', false); // The validations are handled on the inputs, so the Next button should be enabled by default
        }

        if (current >= total) {
          $btnNext.hide();
          $btnFinish.show();
        } else {
          $btnNext.show();
          $btnFinish.hide();
        }
      },

      onNext($tab, $navigation, $index) {
        const currentStep = $tab.find('a')[0].href.split('#')[1];

        if (currentStep === 'step-line-selection') {
          if (!window.vMox.Selections.any()) {
            window.vMox.Flash.danger("You must select a line to continue");
            return false;
          } else {
            Unsuspend.setLineIds();
          }
        }

        return true;
      },

      onTabClick: function ($tab, $navigation, $index) {
        return false;
      },
    });


  }
};

export default Unsuspend;