

/**
 * A utility for verifying an imei number is valid
 */
class ImeiChecker {
  imei: string;
  _baseImei: string;
  _luhnDigit: number;
  errors: string[];

  constructor(imei: string) {
    this.imei = imei;
    this.errors = [];
  }

  static hasValidLength(imei: string) {
    return imei.length >= 15 && imei.length <= 17;
  }

  static hasValidCheckSum(imei: string) {
    let checker = new ImeiChecker(imei);
    return checker.hasCorrectLuhnDigit();
  }

  /**
   * The base imei after removing the luhn digit and any carrier specific digits
   */
  baseImei():string {
    if (this._baseImei) {
      return this._baseImei;
    }
    this._baseImei = this.imei.slice(0, 14);

    return this._baseImei;
  }

  /**
   * the luhn digit for the provided imei(the check digit)
   */
  luhnDigit(): number {
    if (this._luhnDigit || this._luhnDigit === 0) {
      return this._luhnDigit;
    }
    this._luhnDigit = parseInt(this.imei.slice(14, 15));

    return this._luhnDigit;
  }

  /**
   *
   *  the sum of the digits in the imei using the luhn formula
   */
  baseImeiDigitSum(): number {
    let result = 0;

    this.baseImei().split('').forEach((digit, index) => {
      let num: number = parseInt(digit);
      if (index % 2 === 1) {
        num = num * 2;
        if (num >= 10) {
          num = num % 10 + 1;
        }
      }

      result += num;
    });

    return result;
  }

  /**
   *
   * The computed luhn number based on the base imei
   */
  computedLuhnDigit(): number {
    let subtrahend = this.baseImeiDigitSum() % 10;
    
    if (subtrahend == 0){
      return 0;
    }
    else{
      return 10 - subtrahend;
    }
  }

  /**
   *
   * Validates that the imei is the correct length. Acceptable lengths are 15, 16, and 17.
   */
  hasCorrectLength(): boolean {
    return (this.imei.length >= 15 && this.imei.length <= 17);
  }

  /**
   *
   * Validates that the imei has the correct checksum
   */
  hasCorrectLuhnDigit(): boolean {
    return this.luhnDigit() === this.computedLuhnDigit();
  }

}

export default ImeiChecker;