/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/* exported Window */

import Format from '../utilities/format';
import CustomReports from '../pages/custom_reports';
import ImeiChecker from '../utilities/imei';
import ServiceActivation from '../pages/requests/service_activation';
import Unsuspend from '../pages/requests/unsuspend';
import Surveys from '../pages/surveys';

const deviceTypeLogo = {
  'AirCard': 'nfc-signal',
  'Data Only': 'sim-card',
  'Feature Phone': 'mobile-retro',
  'M2M': 'router',
  'MiFi': 'router',
  'Smart Phone': 'mobile-button',
  'Tablet': 'tablet-button',
  'Hot Spot': 'wifi',
  'Wearable': 'watch-smart',
  'BYOD': 'sim-card',
  'Computer': 'computer',
  'Other': 'laptop-mobile',
  'Wireless Router': 'router',
  null: 'laptop-mobile'
};

declare global {
  interface Window {
    vMox: any;
  }
}
window.vMox = window.vMox || {};
window.vMox.Utilities = window.vMox.Utilities || {};

window.vMox.Utilities.Format = Format;
window.vMox.Utilities.deviceTypeLogo = deviceTypeLogo;
window.vMox.Pages.CustomReports = CustomReports;
window.vMox.Utilities.ImeiChecker = ImeiChecker;
window.vMox.Pages.Requests = window.vMox.Pages.Requests || {};
window.vMox.Pages.Requests.ServiceActivation = ServiceActivation;
window.vMox.Pages.Requests.Unsuspend = Unsuspend;
window.vMox.Pages.Surveys = Surveys


declare var module: __WebpackModuleApi.Module;

if (module.hot) {
  module.hot.accept('../utilities/format.ts', function () {
    console.log('Accepting the updated printMe module!');
    window.vMox = window.vMox || {};
    window.vMox.Utilities = window.vMox.Utilities || {};

    window.vMox.Utilities.Format = Format;
  });
}