import 'flexmonster/flexmonster.min.css';
import Flexmonster from 'flexmonster';
import { merge } from 'jquery';

const CustomReports: any = {};

CustomReports.initPivotChart = function (options) {
  let exportFileName = options.exportFileName;
  let pivotChartOptions = {
    container: "pivot-container",
    componentFolder: "/flexmonster/",
    toolbar: true,
    licenseKey: options['flexmonsterLicenseKey'],
    beforetoolbarcreated: function (toolbar) {
      var tabs = toolbar.getTabs();


      toolbar.getTabs = function () {
        tabs.splice(0, 3);
        let exportOptions = {
          filename: `${exportFileName}`
        };
        let exportMenu = tabs[0]["menu"];

        exportMenu[1].handler = () => {
          CustomReports.pivotChart.exportTo("html", {
            filename: `${exportFileName}`
          });
        };

        exportMenu[2].handler = () => {
          CustomReports.pivotChart.exportTo("csv", {
            alwaysEnclose: true,
            filename: `${exportFileName}`
          });
        };

        exportMenu[3].handler = () => {
          CustomReports.pivotChart.exportTo("excel", {
            filename: `${exportFileName}`
          });
        };

        exportMenu[4].handler = () => {
          CustomReports.pivotChart.exportTo("image", {
            filename: `${exportFileName}`
          });
        };

        exportMenu[5].handler = () => {
          CustomReports.pivotChart.exportTo("pdf", {
            filename: `${exportFileName}`,
            pageOrientation: 'landscape'
          });
        };


        return tabs;
      };
    },
    global: {
      localization: {
        grid: {
          blankMember: " ",
          dateInvalidCaption: "N/A"
        }
      }
    }
  };


  if (options.hasOwnProperty('filename')) {
    let dataSource: any = {
      filename: options['filename'],
      ignoreQuotedLineBreaks: false,
    };
    if (options.hasOwnProperty('columnMappings')) {
      dataSource.mapping = options.columnMappings;
    }


    pivotChartOptions['report'] = {
      dataSource: dataSource,
      options: {
        viewType: "grid",
        grid: {
          type: "flat",
          showGrandTotals: "off"
        },
        defaultDateType: "date string", // if changed, must update custom_reports_controller #clean_json_config method
        dateTimePattern: "yyyy-MM-dd HH:mm:ss" // if changed, must update custom_reports_controller #clean_json_config method
      }
    };
  }

  CustomReports.pivotChartOptions = pivotChartOptions;
  CustomReports.pivotChart = new Flexmonster(pivotChartOptions);
};


CustomReports.saveReport = function () {
  var jsonConfig = JSON.stringify(CustomReports.pivotChart.getReport());
  $('#json-config').val(jsonConfig);
  $('#save-custom-report-form').submit();
};

CustomReports.openReport = function (jsonConfig, mapping) {
  let convertedDataSource: any = {
    type: 'csv',
    ignoreQuotedLineBreaks: false,
  };

  convertedDataSource.filename = jsonConfig.dataSource.filename.replace('.json','.csv');

  if (mapping) {
    convertedDataSource.mapping = mapping;
  }

  const customColumns  = jsonConfig.slice.flatOrder.filter((row) => !Object.keys(mapping).includes(row));
  if(customColumns.length) {
    convertedDataSource.mapping = {
      ...convertedDataSource.mapping,
      ...customColumns.reduce((acc, col) => ({ ...acc, [col]: {type: 'string'}}), {}) }
  }

  jsonConfig.dataSource = convertedDataSource;

  CustomReports.pivotChartOptions["report"] = jsonConfig;
  CustomReports.pivotChart.setReport(jsonConfig);
};



CustomReports.billMappings = {
  'Bill Cycle': {
    type: 'default'
  },
  'Phone Number': {
    type: 'string'
  },
  'Employee ID': {
    type: 'string'
  },
  'Employee Email': {
    type: 'string'
  },
  'Employee Name': {
    type: 'string'
  },
  'Employee Cost Center': {
    type: 'string'
  },
  'Manager Id': {
    type: 'string'
  },
  'Manager Email': {
    type: 'string'
  },
  'Manager Name': {
    type: 'string'
  },
  'Manager Cost Center': {
    type: 'string'
  },
  'Account Number': {
    type: 'string'
  },
  'Carrier': {
    type: 'string'
  },
  'User Name': {
    type: 'string'
  },
  'Cost Center': {
    type: 'string'
  },
  'Charge Type': {
    type: 'string'
  },
  'Usage Type': {
    type: 'string'
  },
  'Charge Description': {
    type: 'string'
  },
  'Charge': {
    type: 'number'
  },
  'Quantity': {
    type: 'number'
  },
  'Location Name': {
    type: 'string'
  },
  'Location ID': {
    type: 'default'
  }
};

CustomReports.employeeMappings = {
  'Employee Id': { type: 'string' },
  'Managed By HR': { type: 'string' },
  'First Name': { type: 'string' },
  'Middle Name': { type: 'string' },
  'Last Name': { type: 'string' },
  'Email': { type: 'string' },
  'Title': { type: 'string' },
  'Department': { type: 'string' },
  'Office Phone': { type: 'string' },
  'Office Phone Ext': { type: 'string' },
  'Mobile Phone': { type: 'string' },
  'Cost Center': { type: 'string' },
  'Address 1': { type: 'string' },
  'Address 2': { type: 'string' },
  'City': { type: 'string' },
  'State': { type: 'string' },
  'Zip': { type: 'string' },
  'Zip+4': { type: 'string' },
  'Country': { type: 'string' },
  'Username': { type: 'string' },
  'Manager Id': { type: 'string' },
  'Manager Email': { type: 'string' },
  'Manager Name': { type: 'string' },
  'Manager Title': { type: 'string' },
  'Manager Cost Center': { type: 'string' },
  'Access Level': { type: 'string' },
  'Status': { type: 'string' },
  'Termination Date': { type: 'default' },
  'Do Not Email?': { type: 'string' },
  'Is VIP?': { type: 'string' },
  'Count of Devices': { type: 'number' },
  'Count of Tickets': { type: 'number' },
  'Count of Orders': { type: 'number' },
  'Location Name': { type: 'string' },
  'Location ID': { type: 'default' },
  'Delegate': { type: 'string' },
  'Delegate Order Approval': { type: 'string' }
};

CustomReports.inventoryMappings = {
  'Employee Id': { type: 'string' },
  'Employee Email': { type: 'string' },
  'Employee Name': { type: 'string' },
  'Employee Cost Center': { type: 'string' },
  'Carrier User Name': { type: 'string' },
  'Carrier Cost Center': { type: 'string' },
  'Manager Id': { type: 'string' },
  'Manager Name': { type: 'string' },
  'Manager Cost Center': { type: 'string' },
  'Manager Title': { type: 'string' },
  'Manager Email': { type: 'string' },
  'Device Type': { type: 'string' },
  'Device Make': { type: 'string' },
  'Device Model': { type: 'string' },
  'IMEI': { type: 'string' },
  'ICCID': { type: 'string' },
  'Storage': { type: 'string' },
  'Color': { type: 'string' },
  'Condition': { type: 'string' },
  'Device Status': { type: 'string' },
  'Phone Number': { type: 'string' },
  'Carrier': { type: 'string' },
  'Account Number': { type: 'string' },
  'Contract Start Date': { type: 'default' },
  'Contract End Date': { type: 'default' },
  'Upgrade Eligibility Date': { type: 'default' },
  'VIP Status': { type: 'string' },
  'Most Recent Bill Cost': { type: 'number' },
  'Most Recent Bill Date': { type: 'default' },
  'Phone Number Status': { type: 'string' },
  'Device Location': { type: 'string' },
  'Count of Tickets': { type: 'number' },
  'Count of Orders': { type: 'number' },
  'Logistics Status': { type: 'string' },
  'Location Name': { type: 'string' },
  'Location ID': { type: 'default' },
  'Assigned Approver': { type: 'string' },
  'Date Approver Assigned': { type: 'string' }
};

CustomReports.orderMappings = {
  'Order Id': { type: 'string' },
  'Order Name': { type: 'string' },
  'Order Create Date': { type: 'datetime' },
  'Order Approved Date': { type: 'datetime' },
  'Order Reviewed Date': { type: 'datetime' },
  'Order Submitted Date': { type: 'datetime' },
  'Order SLA Date': { type: 'datetime' },
  'Order Resolution Date': { type: 'datetime' },
  'Within SLA?': { type: 'string' },
  'Days to Provision': { type: 'number' },
  'Carrier': { type: 'string' },
  'Created By': { type: 'string' },
  'Description': { type: 'string' },
  'Status': { type: 'string' },
  'Order Type': { type: 'string' },
  'PO Number': { type: 'string' },
  'Procurement Specialist Name': { type: 'string' },
  'Phone Number': { type: 'string' },
  'Device Type': { type: 'string' },
  'Device Make': { type: 'string' },
  'Device Model': { type: 'string' },
  'IMEI': { type: 'string' },
  'ICCID': { type: 'string' },
  'Serial Number': { type: 'string' },
  'Courier': { type: 'string' },
  'Shipment Tracking Number': { type: 'string' },
  'Shipment Date': { type: 'datetime' },
  'Receive Date': { type: 'datetime' },
  'Attention Of': { type: 'string' },
  'Ship To Phone': { type: 'string' },
  'Ship To Address 1': { type: 'string' },
  'Ship To Address 2': { type: 'string' },
  'Ship To City': { type: 'string' },
  'Ship To State': { type: 'string' },
  'Ship To Zip': { type: 'string' },
  'Ship To Zip+4': { type: 'string' },
  'Ship To Country': { type: 'string' },
  'Shipping Company': { type: 'string' },
  'Employee Id': { type: 'string' },
  'Employee Name': { type: 'string' },
  'Employee Email': { type: 'string' },
  'Employee Cost Center': { type: 'string' },
  'Manager Id': { type: 'string' },
  'Manager Email': { type: 'string' },
  'Manager Name': { type: 'string' },
  'Manager Cost Center': { type: 'string' },
  'Carrier Order Number': { type: 'string' },
  'Accessory Total Cost': { type: 'number' },
  'Accessory Name': { type: 'string' },
  'Accessory Quantity': { type: 'number' },
  'Total Order Cost': { type: 'number' },
  'Location Name': { type: 'string' },
  'Location ID': { type: 'default' }
};

CustomReports.ticketMappings = {
  'Ticket Id': { type: 'string' },
  'Customer Ticket Id': { type: 'string' },
  'Ticket Create Date': { type: 'datetime' },
  'Ticket Create By': { type: 'string' },
  'Ticket Type': { type: 'string' },
  'Ticket Category': { type: 'string' },
  'Ticket Subject': { type: 'string' },
  'Ticket Description': { type: 'string' },
  'Ticket Status': { type: 'string' },
  'Ticket Status Date': { type: 'datetime' },
  'Ticket SLA Date': { type: 'datetime' },
  'Ticket Resolution Date': { type: 'datetime' },
  'Resolution': { type: 'string' },
  'Phone Number': { type: 'string' },
  'Days Open': { type: 'number' },
  'Ticket Within SLA?': { type: 'string' },
  'Ticket Close Date': { type: 'datetime' },
  'Employee Id': { type: 'string' },
  'Employee Name': { type: 'string' },
  'Employee Email': { type: 'string' },
  'Employee Title': { type: 'string' },
  'Employee Cost Center': { type: 'string' },
  'Employee Manager Id': { type: 'string' },
  'Employee Manager Name': { type: 'string' },
  'Employee Manager Email': { type: 'string' },
  'Employee Manager Title': { type: 'string' },
  'Location Name': { type: 'string' },
  'Location ID': { type: 'default' }
};

CustomReports.logisticsMappings = {
  "Shipment Id": { type: 'string' },
  'Order Id': { type: 'string' },
  'Tracking Number': { type: 'string' },
  'Submission Date': { type: 'datetime' },
  'Accept Date': { type: 'datetime' },
  'Ship Date': { type: 'datetime' },
  'Receive Date': { type: 'datetime' },
  'Ship To Name': { type: 'string' },
  'Ship To Phone': { type: 'string' },
  'Addr1': { type: 'string' },
  'Addr2': { type: 'string' },
  'City': { type: 'string' },
  'State': { type: 'string' },
  'Zip': { type: 'string' },
  'Zip4': { type: 'string' },
  'Country': { type: 'string' },
  'Shipping Company': { type: 'string' },
  'Create Date': { type: 'datetime' },
  'Create By': { type: 'string' },
  'Complete Date': { type: 'datetime' },
  'Complete By': { type: 'string' },
  'Attention Of': { type: 'string' },
  'Estimated Delivery Date': { type: 'default' },
  'Shipping Speed': { type: 'string' },
  'Shipment Status': { type: 'string' },
  'Shipment Type': { type: 'string' },
  'Fulfiller': { type: 'string' },
  'Has Return Shipment?': { type: 'string' },
  'Return Tracking Number': { type: 'string' },
  'Return Shipment Status': { type: 'string' },
  'Return Delivery Date': { type: 'default' },
  'Ship On Date': { type: 'default' },
  'Return Pick Up Date': { type: 'default' },
  'Latest Event Description': { type: 'string' },
  'Latest Event Timestamp': { type: 'datetime' },
  'Latest Return Event Description': { type: 'string' },
  'Latest Return Event Timestamp': { type: 'datetime' },
  'Device Owner Employee ID': { type: 'string' },
  'Device Owner Title (Contact)': { type: 'string' },
  'Device Owner Cost Center': { type: 'string' },
  "Device Owner's Manager Name": { type: 'string' },
  "Device Owner's Manager Title": { type: 'string' },
  "Device Owner's Manager Email": { type: 'string' },
  "Device Owner's Manager Cost Center": { type: 'string' },
  'Device Price': { type: 'number' },
  'Device Make': { type: 'string' },
  'Device Model': { type: 'string' },
  'Device Color': { type: 'string' },
  'Device Type': { type: 'string' },
  'Device Carrier': { type: 'string' },
  'Device Storage': { type: 'string' },
  'Pickup Confirmation Number': { type: 'string' },
  'IMEI': { type: 'string' },
  'Location Name': { type: 'string' },
  'Location ID': { type: 'default' },
  "Device Owner's Custom Contact Fields": { type: 'string' }
};

CustomReports.availableInventoryMappings = {
  'Make': { type: 'string' },
  'Model': { type: 'string' },
  'Color': { type: 'string' },
  'Storage': { type: 'string' },
  'Carrier': { type: 'string' },
  'Warehouse Name': { type: 'string' },
  'Available Inventory in Warehouse': { type: 'number' },
  'Available Inventory Pending Fulfillment': { type: 'number' },
  'Available Inventory Remaining': { type: 'number' }
};

export default CustomReports;