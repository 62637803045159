import moment from 'moment';

export const  capitalize = function (string: string): string {
  return string.split(' ').map((word) => {
    return word.slice(0,1).toUpperCase() + word.slice(1, word.length).toLowerCase();
  }).join(' ');
};


export function prettyDate( date: string | moment.Moment, preferredDateFormat: string = "MM/DD/YYYY" ) {
  date = moment(date);
  var offsetDate = moment.parseZone(date);
  var startOfDate = moment(date).startOf('day');
  var today = moment().startOf('day');
  var yesterday = moment().subtract(1, 'day').startOf('day');
  var tomorrow = moment().add(1, 'day').startOf('day');
  if (startOfDate.isSame(today)) {
    return offsetDate.hour() === 0 ? 'Today' : offsetDate.format("[Today @] h:mm a");
  } else if (startOfDate.isSame(yesterday)) {
    return offsetDate.hour() === 0 ? 'Yesterday' : offsetDate.format("[Yesterday @] h:mm a");
  } else if (startOfDate.isSame(tomorrow)) {
    return offsetDate.format("[Tomorrow @] h:mm a");
  } else {
    return offsetDate.format(preferredDateFormat);
  }
}
