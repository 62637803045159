
function validateSurvey(formId: string) {
  $(formId).validate({
    errorClass: 'help-block animated fadeInDown',
    errorElement: 'div',
    errorPlacement: function (error, e) {
      jQuery(e).parents('.survey-question').find('.survey-question__title').append(error);
    },
    highlight: function (e) {
      jQuery(e).closest('.survey-question').find('.survey-question__title').removeClass('has-error').addClass('has-error');
      jQuery(e).closest('.help-block').remove();
    },
    success: function (e) {
      jQuery(e).closest('.survey-question').find('.survey-question__title').removeClass('has-error');
      jQuery(e).closest('.help-block').remove();
    },
    invalidHandler: function(e, validator) {
      $('.survey-button-footer').find('p').show();
    },
    rules: Surveys.currentSurveyRules
  })
}

function submitSurvey(formId: string) {
  let $form = $(formId);
  if ($form.valid()) {
    $form.trigger('submit');
  }
}

const Surveys = {
  currentSurveyRules: {},

  validateSurvey,

  submitSurvey
}

export default Surveys;