import { NoSync } from "../../backbone/base/model";
import { validateForm } from "../../utilities/form";
import ImeiChecker from "../../utilities/imei";



const ServiceActivation = {
  formSelector: '#service-activation-form',
  selectedCatalogItem: new NoSync(),
  selectedPhoneLineId: null as number,
  foundDevice: {} as any,
  foundLine: {} as any,
  useSelectedCatalogItem: false,
  imei: '',
  firstTabFailCallback: null,
  validImei: false,
  wizardObject: null,

  failureCallbacks: {},

  resetImeiSelection() {
    ServiceActivation.selectedCatalogItem = new NoSync();
    ServiceActivation.foundDevice = {};
  },

  setHiddenInputs() {
    let $form = $(ServiceActivation.formSelector);
    var catalogId = ServiceActivation.useSelectedCatalogItem ? ServiceActivation.selectedCatalogItem.get('id') : ServiceActivation.foundDevice.catalog_id;
    $form.find('input[name="request[device_id]"]').val(ServiceActivation.foundDevice.id);
    $form.find('input[name="request[catalog_id]"]').val(ServiceActivation.useSelectedCatalogItem ? ServiceActivation.selectedCatalogItem.get('id') : null);
    $form.find('input[name="request[use_selected_catalog_item]"]').val(`${ServiceActivation.useSelectedCatalogItem}`);
    $form.find('input[name="request[line_ids]"]').val(ServiceActivation.selectedPhoneLineId);
    $form.find('input[name="request[imei]"]').val(ServiceActivation.imei);
    $form.find('input[name="request[assigned_to]"]').val($form.serializeJSON().product_review.preowned[catalogId || 0][0].assignTo);
  },

  loadServiceActivationReview: function () {
    var containerId = '#step-review';
    // Show the loading container
    $(containerId).html('<div class="ajax-loading-indicator"><div class="img-container"><img src="' + window.loading_icon_url + '"></div></div>');

    var loaderId = window.vMox.LoadingIndicator.start([containerId]);
    var catalogId = ServiceActivation.selectedCatalogItem.get('id');
    var imei = ServiceActivation.imei;
    var iccid = null;
    var deviceId = ServiceActivation.foundDevice.id;

    if (ServiceActivation.selectedPhoneLineId == ServiceActivation.foundLine.id) {
      iccid = ServiceActivation.foundLine.iccid;
    } else { // if they manually go back and select a different line
      ServiceActivation.foundLine = {}; //blank out this data to avoid confusion in case they change back
    }
    $.ajax({
      method: 'GET',
      url: '/requests/service_activation_review_table',
      data: {
        catalog_id: ServiceActivation.useSelectedCatalogItem ? catalogId : null,
        imei: imei,
        iccid: iccid,
        device_id: deviceId,
        phone_line_id: ServiceActivation.selectedPhoneLineId
      }
    }).then(function (resp) {
      if (loaderId) {
        window.vMox.LoadingIndicator.stop(loaderId);
      }
      var $btnNext = $('.wizard-next');
      $btnNext.prop('disabled', false);
      $(containerId).html(resp);
    });
  },


  loadCheckoutTab() {
    let formData = $(ServiceActivation.formSelector).serializeJSON();
    var catalogId = ServiceActivation.selectedCatalogItem.get('id');
    var catalogIdForAssignTo = ServiceActivation.useSelectedCatalogItem ? catalogId : ServiceActivation.foundDevice.catalog_id;
    $.ajax({
      url: '/requests/service_activation_checkout',
      method: 'GET',
      data: {
        device_id: ServiceActivation.foundDevice.id,
        imei: ServiceActivation.imei,
        catalog_id: ServiceActivation.useSelectedCatalogItem ? catalogId : null,
        cost_center: formData.request.cost_center,
        assign_to: formData.product_review.preowned[catalogIdForAssignTo || 0][0].assignTo,
        requestNewIccid: formData.request.requestNewIccid,
        iccid: formData.request.iccid,
        area_code: formData.request.area_code,
        phone_line_id: ServiceActivation.selectedPhoneLineId,
        international: formData.request.international || undefined, // deprecated/unused
        tethering: formData.request.tethering || undefined, // deprecated/unused
      }
    }).then((resp) => {
      $('#step-checkout').html(resp);
    });
  },

  initializeWizardValidation() {
    let $form2 = $(ServiceActivation.formSelector);
    $form2.on('keydown', function (e) {
      if (e.key === 'Enter' && $(e.target).prop('tagName') !== 'TEXTAREA') {
        e.preventDefault();
      }
    });

    validateForm($form2, {
      'request[requestNewIccid]': {
        required: true
      },
      'request[iccid]': {
        required: true,
      }
    });

    ServiceActivation.wizardObject = $('.js-wizard-validation').bootstrapWizard({
      tabClass: '',
      previousSelector: '.wizard-prev',
      nextSelector: '.wizard-next',
      onTabShow($tab, $nav, $index) {
        const total = $nav.find('li').length;
        const current = $index + 1;

        const $wizard = $nav.parents('.block');
        const $btnNext = $wizard.find('.wizard-next');
        const $btnFinish = $wizard.find('.wizard-finish');

        if ($tab[0].innerText === 'Review') {
          $btnNext.prop('disabled', true); // Disable the Next button on Review until the contents load
        } else {
          $btnNext.prop('disabled', false); // The validations are handled on the inputs, so the Next button should be enabled by default
        }

        if (current >= total) {
          $btnNext.hide();
          $btnFinish.show();
        } else {
          $btnNext.show();
          $btnFinish.hide();
        }
      },

      onNext($tab, $navigation, $index) {
        const currentStep = $tab.find('a')[0].href.split('#')[1];

        if (currentStep === "step-imei-identification") {
          let validImei = ImeiChecker.hasValidLength(ServiceActivation.imei) && (ServiceActivation.foundDevice.id || ImeiChecker.hasValidCheckSum(ServiceActivation.imei));
          if (!validImei) {
            ServiceActivation.firstTabFailCallback && ServiceActivation.firstTabFailCallback();
            return false;
          } else {
            if (!ServiceActivation.foundDevice.id && !ServiceActivation.selectedCatalogItem.get('id')) {
              window.vMox.Flash.danger('You must select a catalog item');
              return false;
            }
          }
        } else if (currentStep === 'step-line-selection') {
          if (!ServiceActivation.selectedPhoneLineId) {
            window.vMox.Flash.danger('You must select a phone line');
            return false;
          }

          ServiceActivation.loadServiceActivationReview();
        } else if (currentStep === 'step-review') {
          if (!$form2.valid()) {
            return false;
          } else {
            ServiceActivation.setHiddenInputs();
            ServiceActivation.loadCheckoutTab();
          }
        }

        return true;
      },

      onTabClick: function ($tab, $navigation, $index) {
        return false;
      }
    });
  }


};




export default ServiceActivation;