declare global {
  interface Window {
    Backbone: any;
  }
}

export const BaseModel = window.Backbone.Model.extend({
  setUnlessPresent: function (attribute, value) {
    if (!this.has(attribute)) {
      this.set(attribute, value);
    }
  },
});


export const NoSync = BaseModel.extend({
  fetch: function () {
    console.error("This model does not implement fetch");
    this.trigger("sync");
  }
});
