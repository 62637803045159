
interface Address {
  addr1: string;
  addr2: string;
  city: string;
  st: string;
  zip: string;
  zip4?: string;
  country?: string;
}

export const addressLines = function (addressObj: Address): string[] {
  const lines = [];
  if (addressObj.addr1) {
    lines.push(addressObj.addr1);
  }

  if (addressObj.addr2) {
    lines.push(addressObj.addr2);
  }

  let zip = addressObj.zip;
  if (zip && addressObj.zip4) {
    zip = `${zip}-${addressObj.zip4}`;
  }
  let bottomLine = `${addressObj.city || ''}${addressObj.city ? ',' : ''} ${addressObj.st || ''} ${zip}`.trim();

  if (bottomLine) {
    lines.push(bottomLine);
  }

  return lines;
};

export const standardAddressFormat = function (address: Address): string {
  const lines = addressLines(address);
  const linesHtml = lines.map((line) => {
    return `
      <div class="row">
        ${line}
      </div>
    `;
  });

  return `
    <div class+"row">
      ${linesHtml.join("")}
    </div>
  `;
};

export const minimalAddressFormat = function(address: Address) {
  return addressLines(address).join("<br/>");
};
