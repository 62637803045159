

export function validateForm($form: JQuery<HTMLElement>, rules: any, options:any = {}) {
  $form.validate({
    errorClass: 'help-block text-right animated fadeInDown',
    errorClassSpecial: 'help-block animated fadeInDown',
    errorElement: 'div',
    errorPlacement: function (error, e) {
      if ($(e[0]).attr('class') === 'assignDevice') {
        error.removeClass(this.errorClass);
        error.addClass(this.errorClassSpecial);
      }
      jQuery(e).parent().append(error);
      //jQuery(e).parents('.form-group > div').append(error);

    },
    highlight: function (e) {
      jQuery(e).closest('.form-group-submit-order').removeClass('has-error').addClass('has-error');
      jQuery(e).closest('.form-group').removeClass('has-error').addClass('has-error');
      jQuery(e).closest('.help-block').remove();
    },
    success: function (e) {
      jQuery(e).closest('.form-group-submit-order').removeClass('has-error');
      jQuery(e).closest('.form-group').removeClass('has-error');
      jQuery(e).closest('.help-block').remove();
    },
    rules: rules,
    messages: options.messages || {}
  });
}