import { addressLines, standardAddressFormat, minimalAddressFormat } from './format/address';
import { capitalize, prettyDate } from './format/string';
import { currency, internationalCurrency, parse, bigNumber, phoneNumber } from './format/number';

const Format = {
  Address: {
    lines: addressLines,
    standard: standardAddressFormat,
    minimal: minimalAddressFormat
  },
  Number: {
    currency,
    internationalCurrency,
    parse,
    bigNumber,
    phone: phoneNumber
  },
  String: {
    capitalize,
    prettyDate
  },
  test: 18
};

export default Format;